import { modalMachineInstance } from "../..";
import { routerInstance } from "../../../../../../../machines/router/router.instance";

import { ToastFooter } from "../../partials/ToastFooter";
import { ToastHeader } from "../../partials/ToastHeader/ToastHeader";

import { AlertTriangle } from "../../../../../../../../assets/svg/alert-triangle";

import "./TemplateWelcomeModal.css";

export const TemplateWelcomeModal = () => {
  const handleCancel = () => {
    modalMachineInstance.send("CLOSE");
  };
  const handleBackTemplates = () => {
    routerInstance.send("PICK_TEMPLATE");
  };
  return (
    <div className="template-welcome-toast new-colors">
      <div className="template-welcome-toast__icon">
        <AlertTriangle />
      </div>
      <ToastHeader
        title={"Check out other templates"}
        description={
          "You can check another template at any time. However, if you want to keep the changes you made to this template, create an account on our service."
        }
        onClose={handleCancel}
      />
      <ToastFooter onCancel={handleCancel} onSecondaryCta={handleBackTemplates} secondaryCtaText="Back Templates" />
    </div>
  );
};
